<template>
  <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-16' 
    :style='minHeightStyle'>
    <div
      class='background-style text-white py-4 px-4 lg:py-16 lg:px-8 flex-shrink-0'
      :style='`${sidebarBgStyle}`'>
      <ul class='text-xs sidebar-navigation-links flex flex-col lg:sticky lg:top-24'>
        <li class='mb-2 lg:mb-4 border border-transparent hover:border-gray-100 w-full rounded-md'>
          <router-link
            :to='{ name: "AbstractSubmissions" }'
            class='text-lg py-2 pl-2 pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'
            >
            초록접수 확인
          </router-link>
        </li>
        <li class='mb-2 lg:mb-4 border border-transparent hover:border-gray-100 w-full rounded-md'>
          <router-link
            :to='{ name: "ReceiptDownloadFile" }'
            class='text-lg py-2 pl-2 pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'
            >
            영수증 다운로드
          </router-link>
        </li>
        <li v-if='registrationData.payment' class='mb-2 lg:mb-4 border border-transparent hover:border-gray-100 w-full rounded-md'>
          <a href='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/kossoasc/2023files/KSSO(0317-18)_%E1%84%8E%E1%85%A9%E1%84%85%E1%85%A9%E1%86%A8%E1%84%8C%E1%85%B5%E1%86%B8.pdf'
            target='_blank'
            class='text-lg py-2 pl-2 pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'
            >
            초록집 다운로드
          </a>
        </li>
        <li v-if='registrationData.payment' class='mb-2 lg:mb-4 border border-transparent hover:border-gray-100 w-full rounded-md'>
          <a href='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/kossoasc/2023files/KSSO(0317-18)_%E1%84%91%E1%85%B3%E1%84%85%E1%85%A9%E1%84%80%E1%85%B3%E1%84%85%E1%85%A2%E1%86%B7%E1%84%87%E1%85%AE%E1%86%A8.pdf'
            target='_blank'
            class='text-lg py-2 pl-2 pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'
            >
            프로그램북 다운로드
          </a>
        </li>
        <li v-if='registrationData.payment' class='mb-2 lg:mb-4 border border-transparent hover:border-gray-100 w-full rounded-md'>
          <router-link 
            :to='{ name: "CertificateDownload" }'
            target='_blank'
            class='text-lg py-2 pl-2 pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'
            >
            참가확인증 다운로드
          </router-link>
        </li>
      </ul>
    </div>
    <div class='flex-grow relative px-4 lg:px-0 py-8 lg:py-16'>
      <div>
        <div class='flex flex-row justify-start items-stretch gap-x-4'>
          <div class='bg-green-50 rounded-xl px-8 py-4 text-center text-green-900'>
            <circle-check-icon class='h-10 w-10 block mx-auto' stroke-width='1.5' />
            <h3 class='mt-2 text-xl'>가입 완료</h3>
          </div>
          <div 
            class='rounded-xl px-8 py-4 text-center'
            :class='registrationStatusBasedBg'>
            <component :is='registrationCompleteStatusIcon' class='h-10 w-10 block mx-auto' />
            <h3 class='mt-2 text-xl'>{{ registrationCompleteStatus }}</h3>
          </div>
          <div @click='goToAbstractSubmissions' 
            class='standard-transition bg-gray-50 rounded-xl px-8 py-4 text-center hover:shadow-md hover:border cursor-pointer'>
            <h3 class='mt-2 text-3xl'>{{abstractSubmissionCount}}</h3>
            <h3 class='mt-2 text-xl'>초록 제출</h3>
          </div>
        </div>
      </div>
      <div class='w-full max-w-5xl'>
        <h2 class='text-xl font-semibold mt-12 mb-2'>개인 정보</h2>
        <edit-form-field
          class='mb-2 w-full' 
          v-for='formField in profileFormFields'
          :key='`signup-${formField.keyName}`'
          :edit-form-field='formField'
          :value='fieldValue(formField.category, formField.keyName)'
          :is-horizontal-layout='true'
          @update-edit-form-field-value='updateFormFieldValue(formField.category, formField.keyName, ...arguments)' />
      </div>
      <div class='w-full max-w-5xl'>
        <h2 class='text-xl font-semibold mt-12 mb-2'>등록 정보</h2>
        <div v-if='isRegisteredUser'>
          <edit-form-field
            class='mb-2 w-full' 
            v-for='formField in registrationFormFields'
            :key='`registration-${formField.keyName}`'
            :edit-form-field='formField'
            :value='fieldValue(formField.category, formField.keyName)'
            :is-horizontal-layout='true'
            @update-edit-form-field-value='updateFormFieldValue(formField.category, formField.keyName, ...arguments)' />
        </div>
        <div v-else class='bg-gray-100 p-8 mb-4 lg:mb-8 opacity-70'>
          사전등록이 완료되지 않았습니다.
        </div>
      </div>
      <div class='w-full max-w-5xl'>
        <h2 class='text-xl font-semibold mt-12 mb-2'>결제 정보</h2>
        <div v-if='isRegisteredUser'>
          <edit-form-field
            class='mb-2 w-full' 
            v-for='formField in paymentFormFields'
            :key='`registration-${formField.keyName}`'
            :edit-form-field='formField'
            :value='fieldValue(formField.category, formField.keyName)'
            :is-horizontal-layout='true'
            @update-edit-form-field-value='updateFormFieldValue(formField.category, formField.keyName, ...arguments)' />
        </div>
        <div v-else class='bg-gray-100 p-8 mb-4 lg:mb-8 opacity-70'>
          사전등록이 완료되지 않았습니다.
        </div>
      </div>
      <div class='mt-12'>
        <button 
          @click='saveUpdates'
          :disabled='disabledSaveUpdatesButton'
          :class='disabledSaveUpdatesButtonClass'
          class='style-primary px-32 py-3 rounded-md text-white font-semibold'
          style='background-color: var(--darkColor);'>
          <loading-spinner v-if='isLoading' />
          <span v-else>개인 정보 및 등록 정보 업데이트</span>
        </button>
      </div>
      <ul class='list-disc list-inside text-red-600 text-sm mt-2'>
        <li v-if='!profile.name'>
          이름을 입력해주십시오.
        </li>
        <li v-if='!profile.hospitalName'>
          소속을 입력해주십시오.
        </li>
        <li v-if='!isValidPhoneNumber'>
          휴대폰 번호를 확인해주십시오.
        </li>
        <li v-if='!profile.licenseNumber && isRequiredField("license_number")'>
          면허번호 및 자격번호를 입력해주십시오.
        </li>
        <li v-if='!profile.birthDate && isRequiredField("birth_date")'>
          생년월일을 확인해주십시오.
        </li>
        <li v-if='!registrationData.dietitianLicenseNumber && isRequiredField("dietitian_license_number")'>
          임상영양사 자격번호를 입력해주십시오.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { CircleCheckIcon, CircleDottedIcon } from 'vue-tabler-icons'
import camelCase from 'lodash/camelCase'
import isEmpty   from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import jsonLogic from 'json-logic-js'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import dayjs from 'dayjs'

const camelcaseKeys = require('camelcase-keys')

export default {
  name: 'MyPage',
  components: {
    CircleCheckIcon,
    CircleDottedIcon,
    LoadingSpinner,
    'edit-form-field': () => import('@/components/edit-form/EditFormField.vue'), 
  },
  props: {
  },
  data () {
    return {
      profile: {
        extraInfo: {}
      },
      registrationData: {},
      isValidPhoneNumber: false,
      abstractSubmissionCount: 0,
      isLoading: false,
    }
  },
  computed: {
    ...mapFields('users', [
      'signupParams',
      'membershipData',
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'showingEventRegistrationConfigurations',
      'eventMainThemeColor',
    ]),
    customBackgroundStyle () {
      return "background-color: #926699; background-image: url(https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/kossoasc/sidebar_image_v2.png); background-repeat: no-repeat; background-position: top; background-size: 100% auto;"
    },
    sidebarBgStyle () {
      return (this.customBackgroundStyle) ? this.customBackgroundStyle : `background-color: ${this.eventMainThemeColor};`
    },
    minHeightStyle () {
      let minHeight = 64 // size of the navbar
      return `min-height: calc(100vh - ${minHeight}px);`
    },
    registrationCompleteStatusIcon () {
      return this.registrationData.payment ? 'circle-check-icon' : 'circle-dotted-icon'
    },
    registrationCompleteStatus () {
      return this.registrationData.payment ? '등록 완료' : '등록 미완료' 
    },
    registrationStatusBasedBg () {
      return this.registrationData.payment ? 'bg-green-50' : 'bg-gray-50 opacity-60' 
    },
    registrationAllFormFields () {
      return (this.showingEventRegistrationConfigurations) ? this.showingEventRegistrationConfigurations.registrationFormFields.concat().sort((fieldA, fieldB) => fieldA.sortOrder - fieldB.sortOrder) : []
    },
    currentDateTimeString () {
      return dayjs().format('YYYYMMDDHHmm')
    },
    profileFormFields () {
      let profileFormFields = cloneDeep(this.registrationAllFormFields.filter(field => ['name', 'hospital_name', 'phone_number', 'is_kossoasc_member'].includes(field.keyName)))

      profileFormFields = profileFormFields.map(field => {
        if (field.keyName === 'phone_number') {
          field.inputType = 'korean_phone_number'
        } else if (field.keyName === 'is_kossoasc_member') {
          field.inputType = 'read_only'
        } else {
          field.inputType = 'text'
        }
        return field
      })
      profileFormFields.unshift(camelcaseKeys({
          "name": "ID(E-mail)",
          "category": "profile",
          "key_name": "email",
          "meta_data": {
            "helper_text": "",
            "placeholder": "",
            "selectable_options": null
          },
          "input_type": "read_only",
          "sort_order": 1
      }, {deep: true}))
      return profileFormFields
    },
    registrationFormFields () {
      let registrationFormFields = cloneDeep(this.registrationAllFormFields.filter(field => ['registration_type', 'extra_info.medical_profession', 'need_score', 'license_number', 'birth_date', 'participating_sessions', 'marketing_channels', 'dietitian_license_number'].includes(field.keyName)))

      registrationFormFields = registrationFormFields.map(field => {
        if (field.keyName === 'registration_type' || field.keyName === 'extra_info.medical_profession' || field.keyName === 'marketing_channels') {
          field.inputType = 'read_only'
        }
        if (field.keyName === 'marketing_channels') {
          field.metaData.isArray = true
        }
        return field
      })
      return registrationFormFields
        .filter((field) => {
          let condition = field.metaData.showCondition ? field.metaData.showCondition : true
          return jsonLogic.apply(condition, {registrationData: this.registrationData, profile: this.profile, currentDateTime: this.currentDateTimeString})
      })
    },
    paymentFormFields () {
      let paymentFormFields = cloneDeep(this.registrationAllFormFields.filter(field => ['payment_gateway', 'depositor', 'expected_deposit_date'].includes(field.keyName)))

      paymentFormFields = paymentFormFields.map(field => {
        if (field.keyName === 'payment_gateway') {
          field.metaData.showingTitlesByValue = [
            {
              "value": "bank_transfer",
              "title": "계좌이체"
            },
            {
              "value": "iamport-html5_inicis",
              "title": "온라인 결제(카드결제)"
            },
          ]
        }
        if (field.keyName === 'depositor' || field.keyName === 'expected_deposit_date') {
          field.metaData.helperText = ''
        } 
        field.inputType = 'read_only'
        return field
      })
      paymentFormFields.unshift(camelcaseKeys({
        "name": "결제 상태",
        "category": "registration_data",
        "key_name": "payment",
        "meta_data": {
          "helper_text": "",
          "placeholder": "",
          "selectable_options": null,
          "showing_titles_by_value": [
            {
              "value": true,
              "title": "결제 완료"
            },
            {
              "value": false,
              "title": "결제 미완료"
            },
          ]
        },
        "input_type": "read_only",
        "sort_order": 1
      }, {deep: true}))
      paymentFormFields.push(camelcaseKeys({
        "name": "결제 금액",
        "category": "registration_data",
        "key_name": "registration_fee",
        "meta_data": {
          "helper_text": "",
          "placeholder": "",
          "selectable_options": null
        },
        "input_type": "read_only",
        "sort_order": 5
      }, {deep: true}),
      camelcaseKeys({
        "name": "결제 날짜",
        "category": "registration_data",
        "key_name": "paid_at",
        "meta_data": {
          "is_datetime": true,
          "helper_text": "",
          "show_condition": {
            "===": [
              {
                "var": "registrationData.paymentGateway"
              },
              "iamport-html5_inicis"
            ]
          },
          "selectable_options": null,
        },
        "input_type": "read_only",
        "sort_order": 6
      }, {deep: true}),
      camelcaseKeys({
        "name": "입금 확인 날짜",
        "category": "registration_data",
        "key_name": "paid_at",
        "meta_data": {
          "is_datetime": true,
          "helper_text": "",
          "show_condition": {
            "===": [
              {
                "var": "registrationData.paymentGateway"
              },
              "bank_transfer"
            ]
          },
          "selectable_options": null,
        },
        "input_type": "read_only",
        "sort_order": 7
      }, {deep: true}))
      return paymentFormFields
        .filter((field) => {
            let condition = field.metaData.showCondition ? field.metaData.showCondition : true
            return jsonLogic.apply(condition, {registrationData: this.registrationData, profile: this.profile, currentDateTime: this.currentDateTimeString})
        })
    },
    updateParamsForProfile () {
      let params = {
        name: this.profile.name,
        hospital_name: this.profile.hospitalName,
        phone_number: this.profile.phoneNumber,
        license_number: this.profile.licenseNumber,
        birth_date: this.profile.birthDate
      }
      return params
    },
    updateParamsForRegistrationData () {
      return {
        need_score: this.registrationData.needScore,
        participating_sessions: this.registrationData.participatingSessions,
        dietitian_license_number: this.registrationData.dietitianLicenseNumber
      }
    },
    disabledSaveUpdatesButton () {
      return !this.profile.name ||
             !this.profile.hospitalName ||
             !this.isValidPhoneNumber ||
             (!this.profile.licenseNumber && this.isRequiredField("license_number")) ||
             (!this.profile.birthDate && this.isRequiredField("birth_date")) ||
             (!this.registrationData.dietitianLicenseNumber && this.isRequiredField("dietitian_license_number"))
    },
    disabledSaveUpdatesButtonClass () {
      return this.disabledSaveUpdatesButton ? 'opacity-50 cursor-not-allowed' : ''
    },
    isRegisteredUser () {
      return !isEmpty(this.registrationData)
    },
  },
  methods: {
    ...mapActions('abstracts', [
      'getMyAbstracts',
    ]),
    ...mapActions('users', [
      'logout',
      'getProfile',
      'getMembershipData',
      'getRegistrationData',
      'patchProfile',
      'patchRegistrationData',
    ]),
    isRequiredField (keyName) {
      return this.registrationFormFields.find(field => field.keyName === keyName)
    },
    logoutApp () {
      this.logout().then(() => {
        this.$router.push({name: 'Login'})
      })
    },
    updateFormFieldValue (category, keyName, value) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      if (category === 'profile') {
        if (arr.length === 2) {
          this.profile[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          if (keyName === 'phone_number') {
            this.profile.phoneNumber = value.phoneNumber
            this.isValidPhoneNumber = value.isValidPhoneNumber
          } else {
            this.profile[arr[0]] = value
          }
        }
      } else if (category === 'user') {
        if (arr.length === 2) {
          this.user[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.user[arr[0]] = value
        }
      } else if (category === 'registration_data') {
        if (arr.length === 2) {
          this.registrationData[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.registrationData[arr[0]] = value
        }
      } else if (category === 'membership_data') {
        if (arr.length === 2) {
          this.membershipData[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.membershipData[arr[0]] = value
        }
      }
    },
    fieldValue (category, keyName) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      let val = ''
      if (category === 'profile') {
        if (arr.length === 2) {
          val = this.profile[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          if (keyName === 'address') {
            val = `${this.profile.address} (${this.profile.postalCode})`
          } else if (keyName === 'phone_number') {
            val = {
              phoneNumber: this.profile.phoneNumber,
              isValidPhoneNumber: this.isValidPhoneNumber
            }
          } else {
            val = this.profile[arr[0]]
          }
        }
      } else if (category === 'user') {
        if (arr.length === 2) {
          val = this.signupParams[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.signupParams[arr[0]]
        }
      } else if (category === 'registration_data') {
        if (arr.length === 2) {
          val = this.registrationData[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.registrationData[arr[0]]
        }
      } else if (category === 'membership_data') {
        if (arr.length === 2) {
          val = this.membershipData[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.membershipData[arr[0]]
        }
      }
      return val
    },
    goToAbstractSubmissions () {
      this.$router.push({name: 'AbstractSubmissions'})
    },
    downloadReceipt () {
      window.open(`/receipt-download-file`, '_blank')
    },
    saveUpdates () {
      this.isUpdating = true
      const promiseList = []
      promiseList.push(this.patchProfile(this.updateParamsForProfile))
      if (this.isRegisteredUser) {
        promiseList.push(this.patchRegistrationData(this.updateParamsForRegistrationData))
      }
      Promise.all(promiseList).then(() => {
        this.isUpdating = false
        this.$alert('개인 정보 및 등록 정보가 업데이트되었습니다.', {
          confirmButtonText: '확인',
          type: 'success',
          showClose: false,
        }).then(() => {
          // do nothing
        }).catch(() => {})
      })
    },
  },
  mounted () {
    this.getProfile().then((resp) => this.profile = resp)
    this.getRegistrationData().then((resp) => this.registrationData = resp)
    this.getMembershipData()
    this.getMyAbstracts(this.showingEventId).then(resp => {
      this.abstractSubmissionCount = resp.abstract_submissions.length
    })
  }
}
</script>

<style lang='scss' scoped>
  .logout-button {
    transition: all 0.3s ease-in-out;
  }

.background-style {
  background: transparent; 
  width: 100%;
}

.sidebar-navigation-links .router-link-active {
  @apply font-bold border rounded-md;
}
    
@media (min-width: 1024px) {
  .background-style {
    width: 20rem;
    height: auto;
  }
}
</style>
